// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-essays-js": () => import("./../../../src/pages/essays.js" /* webpackChunkName: "component---src-pages-essays-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-js": () => import("./../../../src/pages/logo.js" /* webpackChunkName: "component---src-pages-logo-js" */),
  "component---src-pages-poetry-js": () => import("./../../../src/pages/poetry.js" /* webpackChunkName: "component---src-pages-poetry-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-book-post-js": () => import("./../../../src/templates/book-post.js" /* webpackChunkName: "component---src-templates-book-post-js" */),
  "component---src-templates-category-post-js": () => import("./../../../src/templates/category-post.js" /* webpackChunkName: "component---src-templates-category-post-js" */),
  "component---src-templates-other-book-post-js": () => import("./../../../src/templates/other-book-post.js" /* webpackChunkName: "component---src-templates-other-book-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-poetry-post-js": () => import("./../../../src/templates/poetry-post.js" /* webpackChunkName: "component---src-templates-poetry-post-js" */)
}

